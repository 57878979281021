import { createContainer } from "unstated-next"
import FirebaseState from "../../../state/FirebaseState"
import { useState, useMemo, useEffect } from "react"
import CurrentUserState from "../../../state/CurrentUserState"

const PAGE_SIZE = 5

const useAlbum = () => {
  const { db, storage, firebase } = FirebaseState.useContainer()
  const { currentUser } = CurrentUserState.useContainer()
  const [photos, setPhotos] = useState([])
  const [filteredPhotos, setFilteredPhotos] = useState([])
  const [publicView, setPublicView] = useState(false)
  const [loading, setLoading] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const collection = db.collection("photos")

  useEffect(() => {
    if (publicView) {
      setFilteredPhotos([...photos].filter(d => d.public === true))
    } else {
      setFilteredPhotos([...photos])
    }
  }, [photos, publicView])

  const query = useMemo(() => {
    const cursor = photos[photos.length - 1] && photos[photos.length - 1].createdAt
    let query = collection
    if (currentUser.admin) {
      query = query.orderBy('createdAt', 'desc')
    } else {
      query = query.where("public", "==", true)
      .orderBy('createdAt', 'desc')
    }
    if (photos.length > 0) query = query.startAfter(cursor)
    return query.limit(PAGE_SIZE)
  }, [photos])

  const loadMore = async (page) => {
    
    const snapshot = await query.get()
    if (snapshot.docs.length === 0) return setHasMore(false)
    setPhotos([...photos, ...snapshot.docs.map(d => ({ id: d.id, ...d.data() }))])
  }

  const addPhoto = (photo) => {
    firebase.analytics().logEvent('add_photo', { photo });
    setPhotos([photo, ...photos])
  }

  const getPhotoPath = (photo) => {
    return decodeURI(photo.url
      .replace('https://firebasestorage.googleapis.com/v0/b/benexperiments.appspot.com/o/photos%2F', '')
      .replace(/\?alt.*$/, ''))
  }

  const deletePhoto = async (photo) => {
    const path = getPhotoPath(photo)
    await storage.ref('photos').child(path).delete()
    await collection.doc(photo.id).delete()
    setPhotos([...photos].filter(d => d.id !== photo.id))
    firebase.analytics().logEvent('delete_photo', { photo });
  }

  const updatePhoto = async (photo, payload) => {
    if (typeof payload.public === 'boolean') {
      const path = getPhotoPath(photo)
      await storage.ref('photos').child(path).updateMetadata({ public: payload.public  })
    }
    await collection.doc(photo.id).update(payload)
    firebase.analytics().logEvent('update_photo', { photo, newPhoto: { ...photo, ...payload } });
    setPhotos([...photos].map(p => p.id === photo.id ? {...p, ...payload} : {...p}))
  }

  return { photos, filteredPhotos, addPhoto, deletePhoto, loading, hasMore, loadMore, updatePhoto, publicView, setPublicView }
}
const AlbumState = createContainer(useAlbum)

export default AlbumState