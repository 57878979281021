import React from "react"
import AddPhoto from "./AddPhoto"
import AlbumState from "./index.state"
import Loader, { Loader100vh } from "../../UI/Loader"
import EditableText from "../../UI/EditableText"
import { Title, LockAction, Back, Photos, Wrapper, Photo, Content, Delete, Public } from "./index.styles"
import { FormattedDate } from "react-intl"
import { Previous, Trash, Lock, Unlock, } from "grommet-icons"
import { Link } from "gatsby"
import InfiniteScroll from "react-infinite-scroller"
import CurrentUserState from "../../../state/CurrentUserState"

const Album = () => {
  const { filteredPhotos: photos, deletePhoto, hasMore, loadMore, loading, updatePhoto, publicView, setPublicView } = AlbumState.useContainer()
  const { currentUser } = CurrentUserState.useContainer()

  if (loading) return <Loader100vh />
  return (
    <Wrapper>
      <Link to="/">
        <Back>
          <Previous color="#fff" size="30px" />
        </Back>
      </Link>
      <Title>
        { currentUser.admin && !publicView && <LockAction onClick={() => setPublicView(true) }>
          <Lock color='#fff' />
        </LockAction> }
        { currentUser.admin && publicView && <LockAction onClick={() => setPublicView(false) }>
          <Unlock color='#fff' />
        </LockAction> }
        <span>Les Photos</span>
      </Title>
      <Content>
        { currentUser.admin && <AddPhoto /> }
        <InfiniteScroll
          pageStart={0}
          loadMore={loadMore}
          hasMore={hasMore}
          loader={
            <div className="loader" key={0} style={{
              height: '220px',
              width: '100%'
            }}>
              <Loader />
            </div>
          }
        >
          <Photos>
            {photos.map(d => (
              <Photo key={d.id} src={d.url}>
                <div />
                <div>
                  <EditableText onEdited={val => updatePhoto(d, { title: val })}>{d.title}</EditableText>
                </div>
                <div>
                  <FormattedDate
                    value={new Date(d.createdAt.seconds * 1000)}
                    month="long"
                    day="numeric"
                    weekday="long"
                  />
                </div>
                { currentUser.admin && !d.public && <Public onClick={() => updatePhoto(d, { public: true })}>
                  <Lock />
                </Public> }
                { currentUser.admin && d.public && <Public onClick={() => updatePhoto(d, { public: false })}>
                  <Unlock />
                </Public> }
                { currentUser.admin && <Delete onClick={() => deletePhoto(d)}>
                  <Trash />
                </Delete> }
              </Photo>
            ))}
          </Photos>
        </InfiniteScroll>
      </Content>
    </Wrapper>
  )
}

Album.propTypes = {}

Album.defaultProps = {}

export default () => {
  const { loading } = CurrentUserState.useContainer()
  if (loading) return <Loader100vh />
  return <AlbumState.Provider>
    <Album />
  </AlbumState.Provider>
}
