import FirebaseState from "../state/FirebaseState";
import { useState } from "react";
import CurrentUserState from "../state/CurrentUserState";

const useImageUploader = () => {
  const { firebase } = FirebaseState.useContainer()
  const { currentUser } = CurrentUserState.useContainer()
  const [progress, setProgress] = useState()
  const [uploading, setUploading] = useState(false)
  
  const onError = (err) => {
    setUploading(false)
  }

  const onUploaded = (url) => {
    setUploading(false)
  }

  const upload = async (image, title, publicImage = false, callback) => {
    setUploading(true)
    const now = new Date();
    const filename = `${now.getTime()}-${image.name}`;
    return new Promise((resolve, reject) => {
      const imageRef = firebase.storage().ref("photos").child(filename);
      const uploadTask = imageRef.put(image);
  
      uploadTask.on('state_changed', function(snapshot) {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress)
      })
      
      uploadTask.then((snapshot) => {
          imageRef.getDownloadURL().then(async url => {
              onUploaded(url)
              imageRef.updateMetadata({ public: publicImage })
              const docRef = await firebase.firestore().collection("photos").add({
                createdAt: new Date(),
                url,
                title,
                public: publicImage,
                addedBy: firebase.firestore().doc(`/users/${currentUser.uid}`)
              })
              const doc = await docRef.get()
              resolve({ id: doc.id, ...doc.data() })
          }).catch(reject);
      }).catch(reject);
    })
  }
  return { progress, uploading, upload }
}

export default useImageUploader
