import Cropper from 'react-easy-crop'
import React, { useState, useEffect, useRef, useCallback } from 'react'
import styled from 'styled-components'
import Button from '../../UI/Button'
import getCroppedImg, { toBase64, b64toBlob, compressImage } from './utils'
import { colors } from '../../../theme'
import { Close, Save, RotateRight } from 'grommet-icons'

const Wrapper = styled.div`
  position: fixed;
  z-index: 1000;
  background-color: ${colors.primary};
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  > :nth-child(2) {
    position: absolute;
    top: 30px;
    right: 30px;
    display: flex;
    height: 40px;
  }
  > :last-child {
    position: absolute;
    bottom: 30px;
    right: 30px;
    display: flex;
    height: 40px;
    > :first-child {
      margin-right: 10px;
    }
  }
`

const StyledButton = styled(Button)`
`

const ImageEditor = ({ file, onSubmit, onCancel }) => {
  const [image, setImage] = useState()
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

  const wrapper = useRef()

  useEffect(() => {
    toBase64(file)
      .then(setImage)
  }, [])

  const onCropChange = crop => {
    setCrop(crop)
  }

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const onZoomChange = zoom => {
    setZoom(zoom)
  }

  const onRotateAction = () => {
    setRotation(Math.floor((rotation / 90)) * 90 + 90)
  }

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation
      )
      let blob = await fetch(croppedImage).then(r => r.blob());
      let b64 = await toBase64(blob)
      let b642 = await compressImage(file.type, b64)
      b64toBlob(b642,
        function(blob) {
            blob.lastModifiedDate = file.lastModifiedDate
            blob.name = file.name
            onSubmit(blob)
        });
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels, rotation])

  return (
    <Wrapper ref={wrapper}>
      <Cropper
        image={image}
        crop={crop}
        zoom={zoom}
        minZoom={0.4}
        rotation={rotation}
        aspect={1 / 1}
        restrictPosition={false}
        onCropChange={onCropChange}
        onRotationChange={setRotation}
        onCropComplete={onCropComplete}
        onZoomChange={onZoomChange}
      />
      <div>
        <StyledButton color='#C94277' onClick={onRotateAction}>
          <RotateRight color='#fff' />
        </StyledButton>
      </div>
      <div>
        <StyledButton color='#101D24' onClick={onCancel}>
          <Close color='#fff' />
        </StyledButton>
        <StyledButton color='#C94277' onClick={showCroppedImage}>
          <Save color='#fff' />
          <div>Valider</div>
        </StyledButton>
      </div>
    </Wrapper>
  )
}

export default ImageEditor
