import React from 'react'
import Base from '../layouts/base'
import Album from '../components/pages/Album'

const AlbumPage = (props) => {
  return <Base><Album /></Base>
}

AlbumPage.propTypes = {

}

AlbumPage.defaultProps = {

}

export default AlbumPage
